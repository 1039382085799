var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.hasFreePlan ? _c('CategoryInfoWeakPlan', {
    attrs: {
      "title": "Для доступа к полным данным оплатите тарифный план"
    }
  }, [_vm._v(" Оплатить подписку вы можете в разделе"), _c('br'), _vm._v("\"Тариф и оплата\" ")]) : _c('report-page', [_vm.category ? _c('div', {
    staticClass: "category-path"
  }, [_c('category-path', {
    attrs: {
      "path": _vm.category.c_id_path,
      "categories": _vm.category.categories,
      "light": ""
    }
  })], 1) : _vm._e(), _c('main-title', {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_vm.category ? _c('list-add-item-button', {
          attrs: {
            "entity": "category",
            "mp": _vm.$route.params.mp,
            "item": _vm.category
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e(), _c('switch-input', {
          attrs: {
            "label": "FBS"
          },
          model: {
            value: _vm.fbs,
            callback: function ($$v) {
              _vm.fbs = $$v;
            },
            expression: "fbs"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" Обзор показателей по категории "), _vm.category ? _c('span', {
    staticClass: "blue-text"
  }, [_vm._v(" " + _vm._s(_vm.category && _vm.category.c_name) + " ")]) : _vm._e()]), _vm.cacheErrorMessage && !_vm.cacheLoaded ? _c('span', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.cacheErrorMessage) + " ")]) : _vm.reportSettingsWithFilters ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.loaderConfig,
      "loader": _vm.loadOverview,
      "period": _vm.calendar,
      "forceLoading": !_vm.cacheLoaded,
      "newTabs": _vm.newTabs,
      "noDynamic": _vm.noDynamic
    },
    on: {
      "updateActiveTab": _vm.updateActiveTab
    }
  }) : _vm._e(), _vm.activeTab && _vm.activeTab.id === 3 ? _c('div', {
    staticClass: "monopoly-analysis-wrap"
  }, [_vm.cacheLoaded && _vm.loadedCategoryShareTotalRevenue ? _c('div', {
    staticClass: "monopoly-analysis"
  }, [_c('div', [_c('span', {
    staticClass: "monopoly-analysis__title"
  }, [_vm._v("Товары")]), _c('DoughnutChart', {
    key: _vm.refresh,
    style: _vm.myStylesProducts,
    attrs: {
      "chartData": _vm.chartDataProducts,
      "options": _vm.chartOptions
    }
  })], 1), _c('div', [_c('span', {
    staticClass: "monopoly-analysis__title"
  }, [_vm._v("Бренды")]), _c('DoughnutChart', {
    key: _vm.refresh,
    style: _vm.myStylesBrands,
    attrs: {
      "chartData": _vm.chartDataBrands,
      "options": _vm.chartOptions
    }
  })], 1), _c('div', [_c('span', {
    staticClass: "monopoly-analysis__title"
  }, [_vm._v("Продавцы")]), _c('DoughnutChart', {
    key: _vm.refresh,
    style: _vm.myStylesSellers,
    attrs: {
      "chartData": _vm.chartDataSellers,
      "options": _vm.chartOptions
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _c('main-title', [_vm._v(" Статистика продаж по категории ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables,
      "route-param": "tab"
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings && _vm.tabs_tables_model && _vm.cacheLoaded && !_vm.limitError ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: _vm.tableKey,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "track": _vm.track,
      "file-name": _vm.fileName,
      "forceLoading": !_vm.cacheLoaded
    },
    on: {
      "updateFilters": _vm.updateFilters,
      "limitReached": _vm.limitReached
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };